import { computed, ICONS, navigateTo, useRoute, type ComputedRef } from '#imports'
import type { DashboardSidebarLink } from '#ui-pro/types'
import type { DropdownItem } from '#ui/types'
import type { Module } from './useModules'

const moduleToDashboardSidebarLink = (mod: Module): DashboardSidebarLink => ({
  label: mod.name,
  icon: mod.icon,
  to: mod.disabled ? undefined : `/${mod.id}`,
  tooltip: {
    text: mod.name,
    // shortcuts: [],
  },
  disabled: mod.disabled,
})

const moduleToDropdownItem = (mod: Module): DropdownItem => ({
  label: mod.name,
  to: mod.disabled ? undefined : `/${mod.id}`,
  icon: mod.icon,
  disabled: mod.disabled,
})

export function useNavigation(modules: ComputedRef<Module[]>) {
  const route = useRoute()

  const moduleId = computed({
    get: () => route.path.split('/')[1] ?? 'admin',
    set: (value: string) => navigateTo(`/${value}`),
  })

  const CORE = ['admin']
  const coreModules = computed<Module[]>(() => modules.value.filter(mod => CORE.includes(mod.id)))
  const otherModules = computed<Module[]>(() => modules.value.filter(mod => !CORE.includes(mod.id)))

  const activeModule = computed(() => modules.value.find(mod => mod.id === moduleId.value))

  const isActiveRoute = (name: `${string}-${string}`) => {
    return [name].includes(route.name) || route.name.startsWith(`${name}-id`)
  }

  const LINKS = computed<Record<string, DashboardSidebarLink[]>>(() => ({
    admin: [
      {
        label: 'Asuntos',
        icon: ICONS.issues,
        to: '/admin/issues',
        tooltip: { text: 'Asuntos' },
      },
      {
        label: 'Autenticación',
        icon: 'i-mdi-security',
        children: [
          {
            label: 'Usuarios',
            to: '/admin/auth/users',
            tooltip: { text: 'Usuarios' },
          },
          // DISABLED: until v1 ready
          // {
          //   label: 'Claves API',
          //   to: '/admin/auth/apikeys',
          //   tooltip: { text: 'Claves API' },
          // },
        ],
        defaultOpen: true,
        tooltip: { text: 'Autenticación' },
      },
      {
        label: 'Mensajes',
        icon: ICONS.messages,
        children: [
          // {
          //   label: 'Plantillas',
          //   to: '/admin/messages',
          //   tooltip: { text: 'Plantillas' },
          // },
          {
            label: 'Enviados',
            to: '/admin/messages', // '/admin/messages/items',
            tooltip: { text: 'Mensajes' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Mensajes' },
      },
      {
        label: 'Registros de Auditoría',
        icon: ICONS.auditlogs,
        to: '/admin/auditlogs',
        tooltip: { text: 'Registros de Auditoría' },
      },
    ],
    catalogs: [
      {
        label: 'Categorias',
        icon: ICONS.categories,
        to: '/catalogs/categories',
        tooltip: { text: 'Categorias' },
      },
      {
        label: 'Industrias',
        icon: ICONS.industries,
        to: '/catalogs/industries',
        tooltip: { text: 'Industrias' },
      },
      {
        label: 'Aplicaciones',
        icon: ICONS.applications,
        to: '/catalogs/applications',
        tooltip: { text: 'Aplicaciones' },
      },
      {
        label: 'Productos',
        icon: ICONS.products,
        children: [
          {
            label: 'Productos',
            to: '/catalogs/products',
            tooltip: { text: 'Productos' },
            active: isActiveRoute('catalogs-products'),
          },
          {
            label: 'Items',
            to: '/catalogs/products/items',
            tooltip: { text: 'Items' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Productos' },
      },
      {
        label: 'Servicios',
        icon: ICONS.services,
        children: [
          {
            label: 'Servicios',
            to: '/catalogs/services',
            tooltip: { text: 'Servicios' },
            active: isActiveRoute('catalogs-services'),
          },
          {
            label: 'Items',
            to: '/catalogs/services/items',
            tooltip: { text: 'Items' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Servicios' },
      },
      {
        label: 'Listas de Precios',
        icon: ICONS.pricelists,
        to: '/catalogs/pricelists',
        defaultOpen: true,
        tooltip: { text: 'Listas de Precios' },
      },
      {
        label: 'Directorio',
        icon: 'i-mdi-folder-account',
        children: [
          {
            label: 'Cuentas',
            to: '/catalogs/accounts',
            tooltip: { text: 'Cuentas' },
          },
          {
            label: 'Contactos',
            to: '/catalogs/contacts',
            tooltip: { text: 'Contactos' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Directorio' },
      },
    ],
    finances: [
      {
        label: 'Bancos',
        icon: ICONS.banks,
        to: '/finances/banks',
        tooltip: { text: 'Bancos' },
      },
      {
        label: 'Cuentas Financieras',
        icon: ICONS.financialaccounts,
        to: '/finances/financialaccounts',
        tooltip: { text: 'Cuentas Financieras' },
      },
    ],
    // management: [
    // {
    //   label: 'Dashboards',
    //   icon: 'i-mdi-view-dashboard',
    //   children: [
    //     {
    //       label: 'Comercial',
    //       to: '/management/dashboards/catalogs',
    //       tooltip: { text: 'Comercial' },
    //     },
    //     {
    //       label: 'Finanzas',
    //       to: '/management/dashboards/finances',
    //       tooltip: { text: 'Finanzas' },
    //     },
    //     {
    //       label: 'Operaciones',
    //       to: '/management/dashboards/operations',
    //       tooltip: { text: 'Operaciones' },
    //     },
    //     {
    //       label: 'Ventas',
    //       to: '/management/dashboards/sales',
    //       tooltip: { text: 'Ventas' },
    //     },
    //   ],
    //   defaultOpen: true,
    //   tooltip: { text: 'Directorio' },
    // },
    //   {
    //     label: 'Directorio',
    //     icon: 'i-mdi-folder-account',
    //     children: [
    //       {
    //         label: 'Cuentas',
    //         to: '/management/accounts',
    //         tooltip: { text: 'Cuentas' },
    //       },
    //       {
    //         label: 'Contactos',
    //         to: '/management/contacts',
    //         tooltip: { text: 'Contactos' },
    //       },
    //     ],
    //     defaultOpen: true,
    //     tooltip: { text: 'Directorio' },
    //   },
    // ],
    operations: [
      {
        label: 'Producción',
        icon: ICONS.productionorders,
        children: [
          {
            label: 'Ordenes de Producción',
            to: '/operations/productionorders',
            tooltip: { text: 'Ordenes de Producción' },
            active: isActiveRoute('operations-productionorders'),
          },
          {
            label: 'Items',
            to: '/operations/productionorders/items',
            tooltip: { text: 'Items' },
          },
          {
            label: 'Aprobación',
            to: '/operations/productionorders/approval',
            tooltip: { text: 'Aprobación' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Ordenes de Producción' },
      },
      {
        label: 'Cumplimiento',
        icon: ICONS.serviceorders,
        children: [
          {
            label: 'Ordenes de Servicio',
            to: '/operations/serviceorders',
            tooltip: { text: 'Ordenes de Servicio' },
            active: isActiveRoute('operations-serviceorders'),
          },
          {
            label: 'Items',
            to: '/operations/serviceorders/items',
            tooltip: { text: 'Items' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Ordenes de Servicio' },
      },
    ],
    sales: [
      {
        label: 'Negocios',
        icon: ICONS.deals,
        to: '/sales/deals',
        tooltip: { text: 'Negocios' },
      },
      {
        label: 'Actividades',
        icon: ICONS.activities,
        to: '/sales/activities',
        tooltip: { text: 'Actividades' },
      },
      {
        label: 'Consultas',
        icon: ICONS.inquiries,
        to: '/sales/inquiries',
        tooltip: { text: 'Consultas' },
      },
      {
        label: 'Campañas',
        icon: ICONS.campaigns,
        to: '/sales/campaigns',
        tooltip: { text: 'Campañas' },
      },
      {
        label: 'Cotizaciones',
        icon: ICONS.quotes,
        to: '/sales/quotes',
        tooltip: { text: 'Cotizaciones' },
        active: isActiveRoute('sales-quotes'),
      },
      {
        label: 'Ordenes de Venta',
        icon: ICONS.salesorders,
        to: '/sales/salesorders',
        tooltip: { text: 'Ordenes de Venta' },
        active: isActiveRoute('sales-salesorders'),
      },
      {
        label: 'Directorio',
        icon: 'i-mdi-folder-account',
        children: [
          {
            label: 'Cuentas',
            to: '/sales/accounts',
            tooltip: { text: 'Cuentas' },
            active: isActiveRoute('sales-accounts'),
          },
          {
            label: 'Sucursales',
            to: '/sales/branches',
            tooltip: { text: 'Sucursales' },
          },
          {
            label: 'Contactos',
            to: '/sales/contacts',
            tooltip: { text: 'Contactos' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Directorio' },
      },
    ],
  }))

  const links = computed<DashboardSidebarLink[][]>(() => {
    if (moduleId.value in LINKS.value) return [LINKS.value[moduleId.value]]
    return [
      otherModules.value.map(moduleToDashboardSidebarLink), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDashboardSidebarLink),
    ]
  })

  const items = computed<DropdownItem[][]>(() => {
    return [
      otherModules.value.map(moduleToDropdownItem), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDropdownItem),
    ]
  })

  const isModuleActive = computed(() => modules.value.some(mod => route.path.startsWith(`/${mod.id}`)))

  return {
    moduleId,
    coreModules,
    otherModules,
    activeModule,
    links,
    items,
    isModuleActive,
  }
}
